/* 数组字典 */

// 控件类型
export const ControlTypes = {
  1: '输入框',
  2: '下拉框',
  3: '单选框',
  4: '复选框',
  5: '标签组件',
  6: '图片'
} 